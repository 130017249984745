:root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;

  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  background-color: #242424;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

a {
  font-weight: 500;
  color: #646cff;
  text-decoration: inherit;
}
a:hover {
  color: #535bf2;
}

body {
  margin: 0;
  display: flex;
  place-items: center;
  min-width: 320px;
  min-height: 100vh;
}

h1 {
  font-size: 3.2em;
  line-height: 1.1;
}

button {
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  background-color: #1a1a1a;
  cursor: pointer;
  transition: border-color 0.25s;
}
button:hover {
  border-color: #646cff;
}
button:focus,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}

select {
  appearance: none;
  padding: 0.5rem 1rem 0.5rem 1rem;
  font-size: 1rem;
  border: 1px solid #818181;
  border-radius: 10px;
}

select:focus {
  outline: none;
  border-color: #5c6ac4;
  box-shadow: 0 0 0 2px rgba(92, 106, 196, 0.5);
}

input {
  padding: 13px;
  border: 1px solid #818181;
  border-radius: 5px;
}

@media (prefers-color-scheme: light) {
  /* :root {
    color: #213547;
    background-color: #ffffff;
  }
  a:hover {
    color: #747bff;
  } */
  button {
    color: #f9f9f9;
  }
}

/* Home */
@keyframes floating {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.floating-heading {
  font-size: 4em;
  background: linear-gradient(to right, #FF4500, #FFA500, #FFFF00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: floating 3s ease-in-out infinite;
  margin: 0.4em;
}

.home-subheading {
  font-size: 18px;
  font-weight: 500;
}

.home-body {
  font-weight: 300;
  width: 750px;
}

.home-quote-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.home-quote {
  font-size: 14px;
  font-style: italic;
  width: 550px;
}

.home-quote-photo {
  width: 90px;
  border-radius: 50px;
}

.home-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  padding-top: 15px;
}

.secondary-button {
  background-color: transparent;
  border: 1px solid #1a1a1a;
  box-shadow: inset 0 0 0 3px #1a1a1a;
}

.vocals-checkbox {
  transform: scale(1.5);
}


/* Timer */

.timer-container {
  display: flex;
  padding-top: 5px;
  justify-content: center;
}

.timer-controls {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
}

.timer-digits {
  font-size: 3em;
  font-weight: 700;
  background: linear-gradient(to right, rgb(152, 73, 222), rgba(74, 45, 224));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* width: 138px;
  height: 72px; */
}

/* Global */
.main-container {
  transform: scale(1.25);
}

.b-container {
  /* display: flex; */
  /* flex-direction: row; */
  gap: 100px;
}

/* Vocals */

.vocals-text {
  width: 200px;
  font-size: 14px;
}

/* Progress Bar */

.progress-container {
  padding-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

}

.horizontal-list {
  display: flex;
  font-size: 10px;
  margin: 0;
  font-weight: 600;
  color: rgb(107, 105, 105);
  border: 2px solid rgba(141, 141, 141, 0.2);
  border-radius: 5px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.horizontal-list li {
  margin-right: 30px; 
}

.selected {
  font-weight: 700;
  color: rgb(225, 225, 225);
}

/* Settings */

.settings-container {
  display: flex;
  flex-direction: column;
}

.settings-link {
  padding: 10px;
  font-size: 20px;
  font-weight: 600;
}

.sign-out {
  color: darkred;
}

/* Start */

.start-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
}

.start-heading {
  margin-bottom: 10px;
}

.start-subtext {
  margin-top: 0;
  margin-bottom: 20px;
  font-style: italic;
  font-weight: 300;
}

/* Sounds */

.sounds-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sounds-header {
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 10px;
}

.sounds-subtext {
  font-weight: 400;
  margin-top: 10px;
  font-style: italic;
  font-size: 16px;
}

.sound-list-actions {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.custom-sounds-container {
  justify-content: center;
  display: flex;
  gap: 15px;
}

.continue-button {
  border: 1px solid rgba(87, 65, 200, 0.5);
  background: linear-gradient(to right, rgb(152, 73, 222), rgba(74, 45, 224));
}

.sounds-item-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 25px;
  width: 850px;
}

.sound-item {
  display: flex;
  align-items: center;
  margin: 5px;
  width: 160px;
  height: 50px;
  border-radius: 25px;
  cursor: pointer;
  font-weight: 600;
}

.sound-checkbox {
  transform: scale(1.3);
  margin-right: 10px;
  margin-left: 20px;
}

.onboard-progress-bar {
  transform: scale(1.25);
  /* margin-top: 10px;
  margin-bottom: 10px; */
}


/* Auth */

.auth-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.auth-subheader {
  margin-bottom: 10px;
  font-weight: 300;
}

.auth-subtext {
  margin-top: 0;
  margin-bottom: 20px;
  font-weight: 300;
  font-size: 16px;
}

.auth-signup-link {
  margin-top: 20px;
}

.google-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

hr.solid {
  border: 0.5px solid rgba(141, 141, 141, 0.2);
  width: 45%;
  margin-top: 10px;
  margin-bottom: 10px;
}


/* Track List */
.track-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  list-style-type: none;
  text-align: left;
  padding-left: 0;
}

.track-list-item {
  border-radius: 12px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  transition: border-color 0.25s;
  box-shadow: 1px 1px 1px 1px rgba(123, 135, 211, 0.3);
}

.harmony  {
  background-color: #535bf2;
}

.melody {
  background-color: rgba(2, 164, 223, .92);
}

.drums {
  background-color: rgb(230, 78, 104);
}

.bass {
  background-color: rgb(152, 73, 222);

}

.track-list-label {
 color: rgba(230, 227, 227, 0.9);
 font-weight: 400;
 font-style: italic;
 font-size: 12px;
 margin-top: 0px;
 margin-bottom: 5px;
}

/* Arrangement */
.arrange-title {
  margin-top: 40px;
}